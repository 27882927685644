import Swiper, { Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';

export default class Slider {
  constructor() {
    document.addEventListener("DOMContentLoaded", function () {
      Swiper.use([Navigation]);

      const homeWhatIsNew = new Swiper('.home-what-is-new', {
        slidesPerView: 2,
        spaceBetween: 10,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 30,
          },
          1440: {
            slidesPerView: 7.8,
            spaceBetween: 50,
          },
        }
      });

      const homeProducts = new Swiper('.home-products-slider', {
        slidesPerView: 'auto',
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });

      // Product Detail
      const productDetailMain = new Swiper('.product-detail-slider', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });

      const productMediaDevices = new Swiper('.product-medical-devices', {
        slidesPerView: 2,
        spaceBetween: 20,
        loop: true,
        loopedSlides: 3,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 2.9,
            spaceBetween: 50,
          },
        }
      });

      const productOemManufacture = new Swiper('.product-oem-manufacture', {
        slidesPerView: 2,
        spaceBetween: 20,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 2.9,
            spaceBetween: 50,
          },
        }
      });

      const productRawMaterials = new Swiper('.product-raw-materials', {
        slidesPerView: 2,
        spaceBetween: 20,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 2.9,
            spaceBetween: 50,
          },
        }
      });
    });
  }
}
