import Modal from "flowbite/src/components/modal";
import Cookies from "js-cookie";

export default class Common {
  constructor() {
    this.targetEl = document.getElementById("toast_message");
    this.popup = document.querySelector(".js-popup");
    this.popupClose = document.querySelector(".js-popup-close");
    this.popupOverlay = document.querySelector(".js-popup-overlay");
    this.passwordResetButton = document.querySelector(".js-reset-password");

    this.init();
  }

  init = () => {
    if (this.targetEl) {
      const dismiss = new Dismiss(this.targetEl);

      setTimeout(() => {
        dismiss.hide();
      }, 5000);
    }

    const popupCookie = Cookies.get("popup");

    if (this.popup && popupCookie == undefined) {
      this.popup.classList.add("-open");

      this.popupClose.addEventListener("click", () => {
        this.popup.classList.remove("-open");
        Cookies.set("popup", "true", { expires: 1 });
      });
    }
  };
}
