const { Dropzone } = require("dropzone");

export default class Drop {
  constructor() {
    // $(document).ready(function () {
    //   let uploadedDocumentMap = {};
    //   Dropzone.autoDiscover = false;

    //   let myDropzone = new Dropzone("#dropzone", {
    //     url: "/my-profile/add-product/file-upload",
    //     addRemoveLinks: true,
    //     maxFilesize: 5,
    //     parallelUploads: 10,
    //     uploadMultiple: true,
    //     autoProcessQueue: false,
    //     uploadMultiple: true,
    //     acceptedFiles: ".jpg, .jpeg, .png",
    //     headers: {
    //       "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    //     },
    //     successmultiple: function (data, response) {
    //       $.each(response["name"], function (key, val) {
    //         $("#dropzone").append(
    //           '<input type="hidden" name="images[]" value="' + val + '">'
    //         );
    //         uploadedDocumentMap[data[key].name] = val;
    //       });
    //     },
    //     removedfile: function (data, response) {
    //       file.previewElement.remove();
    //       let name = "";
    //       if (typeof file.file_name !== "undefined") {
    //         name = file.file_name;
    //       } else {
    //         name = uploadedDocumentMap[file.name];
    //       }
    //       $("form")
    //         .find('input[name="images[]"][value="' + name + '"]')
    //         .remove();

    //       $.ajaxSetup({
    //         headers: {
    //           "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    //         },
    //       });

    //       $.ajax({
    //         type: "POST",
    //         headers: {
    //           Accept: "application/json",
    //         },
    //         data: {
    //           path: name,
    //         },
    //         url: "/my-profile/product/file-remove" + "/" + name,
    //         success: (res) => {
    //           console.log("deleteeee", res);
    //         },
    //       });
    //     },
    //   });

    //   myDropzone.on("addedfile", (data, response) => {
    //     $.each(response["name"], function (key, val) {
    //       $(".dropzone").append(
    //         '<input type="hidden" name="images[]" value="' + val + '">'
    //       );
    //       uploadedDocumentMap[data[key].name] = val;
    //     });
    //   });

    //   myDropzone.on("error", function (file, message) {
    //     alert(message);
    //     myDropzone.removeFile(file);
    //   });

      // myDropzone.on('removedfile', ($file) => {
      //   console.log(`File remove: ${file.name}`);
      // });
    // });
  }
}
