export default class ProductDetail {
  constructor() {
    this.categoryButton = document.querySelector(".js-categories-link");
    this.categorySubMenu = document.querySelector(".js-categories-sub-menu");
    this.tabs = document.querySelector(".js-tab-menu-wrapper");
    this.tabButton = document.querySelectorAll(".js-tab-menu-button");
    this.contents = document.querySelectorAll(".js-menu-content");

    this.init();
  }

  init = () => {
    this.categoryButton?.addEventListener("click", this.openCategoryMenu);
    this.tabMenu();
  };

  openCategoryMenu = () => {
    this.categorySubMenu?.classList.toggle("open");
  };

  tabMenu = () => {
    if (this.tabs) {
      this.tabs.onclick = (e) => {
        const id = e.target.dataset.id;

        if (id) {
          this.tabButton.forEach((btn) => {
            btn.classList.remove("active");
          });
          e.target.classList.add("active");

          this.contents.forEach((content) => {
            content.classList.remove("active");
          });
          const element = document.getElementById(id);
          element.classList.add("active");
        }
      };
    }
  };
}
