export default class MobileMenu {
  constructor() {
    this.body = document.querySelector("body, html");
    this.mobileMenuIcon = document.querySelector(".js-mobile-menu-icon");
    this.mobileMenu = document.querySelector(".js-mobile-nav");
    this.mobileCloseIcon = document.querySelector(".js-mobile-close-icon");
    this.subMenus = document.querySelectorAll(".js-sub-menu");
    this.navLinks = document.querySelectorAll(".js-nav-link");

    this.init();
  }

  init = () => {
    this.mobileMenuIcon?.addEventListener("click", this.openMobileMenu);
    this.mobileCloseIcon?.addEventListener("click", this.closeMobileMenu);
    this.navLinks?.forEach((navLink) =>
      navLink.addEventListener("click", this.openSubMenu)
    );
  };

  openSearchForm = () => {
    this.searchIcon.classList.toggle("-show");
    this.searchForm.classList.toggle("-show");
  };

  openMobileMenu = () => {
    this.mobileMenu.classList.add("-open");
    this.body.style.overflow = "hidden";
    this.body.style.position = "relative";
  };

  closeMobileMenu = () => {
    this.mobileMenu.classList.remove("-open");
    this.body.style.overflow = "";
    this.body.style.position = "";
  };

  openSubMenu = (e) => {
    e.preventDefault();
    const target = e.target;
    const parentEl = target.parentNode;
    const subMenu = parentEl.querySelector(".js-sub-menu");
    const href = target.getAttribute("href");

    console.log(target);

    if (subMenu) {
      subMenu.classList.toggle("-open");
      target.classList.toggle("-open");
    } else {
      window.location.href = href;
    }
  };
}
