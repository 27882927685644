global.$ = global.jQuery = require('jquery');
require('./bootstrap');
require('./favorite');
// var dropzone = require('dropzone');

import MobileMenu from './mobile-menu';
import Slider from './slider';
import { Fancybox } from "@fancyapps/ui";
import Categori from './categori';
import Footer from './footer';
import 'flowbite';
import ProductDetail from './product-detail';
import Common from './common';
import Drop from './drop';
// import Components from './components';

new MobileMenu();
new Slider();
new Categori();
new Footer();
new ProductDetail();
new Common();
new Drop();
// new Components();
