$(function () {
  $(".js-fav-button").on('click', function (e) {
    e.preventDefault();
    let productId = parseInt($(this).data('id'));

    $.ajaxSetup({
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
      },
    });

    $.ajax({
      type: "POST",
      headers: {
        Accept: "application/json",
      },
      data: {
        'product_id': productId
      },
      url: "/favorite" + '/' + productId,
      success: (res) => {
        if (res.status == 200) {
          const favButton = $(this).find('svg');

          favButton.addClass('--heart-full');
        } else if (res.status == 201) {
          const favButton = $(this).find('svg');
          const pathname = window.location.pathname;

          favButton.removeClass('--heart-full');

          if (pathname === '/favorites') {
            window.location.reload();
          }
        } else if (res.status == 307) {
          $('#signupModal').modal('show');
        }
      }
    });
  });
});
