export default class Categori {
  constructor() {
    this.categoriMenuItems = document.querySelectorAll(".js-categori-menu");
    this.categoriSubMenuItems = document.querySelectorAll(
      ".js-categori-sub-menu"
    );
    this.categoriChildLink = document.querySelectorAll(
      ".js-categori-child-link"
    );

    this.categoriSideMenuToggle = document.querySelector(".js-categori-side-menu-toggle-btn");
    this.categoriSideMenu = document.querySelector(".js-categori-side-menu");
    this.overlay = document.querySelector(".js-overlay");
    this.sideMenuIcon = document.querySelector(".js-categori-side-menu-icon");

    this.init();
  }

  init = () => {
    this.categoriMenuItems.forEach((categoriMenuItem) => {
      categoriMenuItem.addEventListener(
        "click",
        this.actions.bind(this),
        false
      );
    });

    this.categoriChildLink.forEach((el) => {
      if (el.closest(".-active")) {
        el.closest(".js-categori-menu").classList.add("-active");
      }
    });

    this.orderSelect();

    this.categoriSideMenuToggle?.addEventListener("click", ()=> {
      this.toggleSideMenu();
      this.changeIcon();
    });
  };

  changeIcon = () => {
    this.sideMenuIcon.classList.toggle('ph-caret-down');
    this.sideMenuIcon.classList.toggle('ph-x');
  }

  toggleSideMenu = () => {
    this.categoriSideMenu.classList.toggle("-active");
    this.overlay.classList.toggle("-show");
  }

  actions = (item) => {
    this.removeActiveClass(this.categoriMenuItems);
    this.addActiveClass(item);
  };

  removeActiveClass = (items) => {
    items.forEach((item) => item.classList.remove("-active"));
  };

  addActiveClass = (element) => {
    const target = element.currentTarget;
    target.classList.add("-active");
  };

  orderSelect = () => {
    const areaSelect = document.querySelector(".js-order-select");

    areaSelect?.addEventListener('change', (e) => {
      const select = e.target;
      const value = select.selectedOptions[0].value;
      const options = areaSelect.querySelectorAll("option");

      options.forEach((option) => {
        if (option.value == value) {
          option.setAttribute("selected", "selected");
          window.location.search = "?order=" + value;
        } else {
          option.removeAttribute("selected", "selected");
        }
      });
    });
  };
}
