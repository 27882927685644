export default class Footer {
  constructor() {
    this.init();
  }

  init = () => {
    this.backToTop();
    this.scrool();
  };

  backToTop = () => {
    document.querySelector(".js-back-to-top")?.addEventListener("click", () => {
      window.scrollTo(0, 0);
    });
  };

  scrool = () => {
    let lastKnownScrollPosition = 0;
    let ticking = false;

    function doSomething(scrollPos) {
      if (scrollPos > 200) {
        document.querySelector(".js-back-to-top")?.classList.add("-active");
      } else {
        document.querySelector(".js-back-to-top")?.classList.remove("-active");
      }
    }

    document.addEventListener("scroll", function (e) {
      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(function () {
          doSomething(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    });
  };
}
